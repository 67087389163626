(function ($) {
  $(document).ready(function () {
    $(".wpcb-lotto-single__sidebar > a").on("click", function () {
      const tab_id = $(this).attr("data-tab");
      const tab_title = $(this).text();

      $(".wpcb-lotto-single__sidebar > a").removeClass("wpcb-lotto-single__sidebar-link-active");
      $("article.wpcb-lotto-col-sm-9").css("display", "none");

      $(this).addClass("wpcb-lotto-single__sidebar-link-active");
      $("#wpcb-lotto-tab-" + tab_id).css("display", "block");
      $(".wpcb-lotto-single__header-title").text(tab_title);

      $("html, body").animate(
        {
          scrollTop: $("#wpcb-lotto-tab-" + tab_id).offset().top,
        },
        500
      );
    });

    $("#wpcbLottoThaiCheckForm").submit(function (e) {
      e.preventDefault();
      let thaiLotto = $(this).data("thai-lotto");

      let $lottoThaiMessage = $("#wpcbLottoThaiMessage");
      let msg = "ไม่ถูกรางวัลใด ๆ";

      let $lottoThaiResultNumber = $("#wpcbLottoThaiResultNumber");
      let filter = $lottoThaiResultNumber.val();

      if ("" === filter) {
        return false;
      }

      let intRegex = /^\d+$/;
      if (intRegex.test(filter) && filter.length !== 6) {
        $lottoThaiMessage.html('<p class="wpcb-lotto-thai__message-in-text">กรุณาระบุหมายเลขสลากให้ถูกต้อง</p>');
        return false;
      }

      let numberWithCommas = function (x) {
        return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      };

      let d2 = filter.substring(4, 6);
      let d3f = filter.substring(0, 3);
      let d3l = filter.substring(3, 6);

      let map = new Map(Object.entries(thaiLotto));
      map.forEach(function (lotto, index) {
        for (let i = 0; i < lotto.numbers.length; i++) {
          let val = lotto.numbers[i];

          if (filter == val) {
            if (index == 11) {
              msg = filter + " ถูกรางวัลข้างเคียงรางวัลที่ 1 จำนวนเงิน " + numberWithCommas(lotto.reward) + " บาท";
            } else {
              msg = filter + " ถูกรางวัลที่ " + index + " จำนวนเงิน " + numberWithCommas(lotto.reward) + " บาท";
            }
          } else if (d2 == val) {
            msg = filter + " ถูกรางวัล เลขท้าย 2 ตัว จำนวนเงิน " + numberWithCommas(lotto.reward) + " บาท";
            break;
          } else if (d2 == val) {
            msg = filter + " ถูกรางวัล เลขท้าย 2 ตัว จำนวนเงิน " + numberWithCommas(lotto.reward) + " บาท";
            break;
          } else if (d3l == val && index == 6) {
            msg = filter + " ถูกรางวัล เลขท้าย 3 ตัว จำนวนเงิน " + numberWithCommas(lotto.reward) + " บาท";
            break;
          } else if (d3f == val && index == 10) {
            msg = filter + " ถูกรางวัล เลขหน้า 3 ตัว จำนวนเงิน " + numberWithCommas(lotto.reward) + " บาท";
            break;
          }
        }
      });

      $lottoThaiMessage.html('<p class="wpcb-lotto-thai__message-in-text">' + msg + "</p>");
    });
  });
})(jQuery);
